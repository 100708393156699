import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Container from '../components/container';

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <Container>
      <div>
        <h1>Sorry, that thing you were expecting has gone.</h1>
        <p>But here's some of <a href="/">my projects</a></p>
      </div>
    </Container>
  </Layout>
)

export default NotFoundPage
